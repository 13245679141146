<template>
  <b-sidebar
    id="create-order-payout-user-bonuses-sidebar"
    :visible="isPanelOrderPayoutBonusesActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-panel-order-payout-bonuses-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          заявка на выплату бонусов
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refPayoutForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >

          <b-form-group
            label="Способ зачисления"
            label-for="methodCrediting"
          >

            <b-form-radio
              v-model="selected"
              name="methodCrediting"
              value="2"
            >
              По номеру карты
            </b-form-radio>
            <b-form-radio
              v-model="selected"
              name="methodCrediting"
              value="1"
            >
              По номеру телефона
            </b-form-radio>

            <b-row
              v-if="selected === '2'"
              class="my-1"
            >

              <b-col
                sm="10"
                class="mb-50"
              >

                <validation-provider
                  v-slot="{ errors }"
                  :rules="{min_value: 500, max_value:availableBonuses, required}"
                  name="сумма бонусов"
                >
                  <b-form-input
                    v-model="sumBonusesValue"
                    placeholder="Введите сумму бонусов к выплате"
                    type="number"
                    autocomplete="off"
                    autofocus
                  />

                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>

              </b-col>

              <b-col
                sm="10"
                class="mb-50"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="ФИО"
                  rules="required"
                >
                  <b-form-input
                    v-model="userNameValue"
                    placeholder="Введите ФИО"
                    type="text"
                    autocomplete="off"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider></b-col>
              <b-col sm="10">

                <validation-provider
                  v-slot="{ errors }"
                  name="номер банковской карты"
                  rules="required|length:19"
                >
                  <b-form-input
                    v-model="cardNumberValue"
                    v-mask="'####-####-####-####'"
                    placeholder="16 цифр номера банковской карты"
                    autocomplete="off"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>

              </b-col>
            </b-row>
            <b-row
              v-if="selected === '1'"
              class="my-1"
            >

              <b-col

                sm="10"
                class="mb-50"
              >

                <validation-provider
                  v-slot="{ errors }"
                  :rules="{min_value: 500, max_value:availableBonuses, required}"
                  name="сумма бонусов"
                >
                  <b-form-input
                    v-model="sumBonusesValue"
                    placeholder="Введите сумму бонусов к выплате"
                    type="number"
                    autocomplete="off"
                    autofocus
                  />

                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>

              </b-col>

              <b-col sm="10">

                <validation-provider
                  v-slot="{ errors }"
                  name="номер телефона"
                  rules="required|mobilephone"
                >
                  <b-form-input
                    v-model="phoneNumberValue"
                    v-mask="'+7(###)###-##-##'"
                    placeholder="+79101001010"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-col>
            </b-row>

          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Отправить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
import ru from 'vee-validate/dist/locale/ru.json'
import {
  min_value, max_value, length,
} from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'
import { createPayout } from '@/application/payoutDataService'
import mobilephone from '@/validations/mobileValidates'
import { resolveUserPayoutMotivationStatus } from '@/utils/UserFilter'

localize('ru', ru)
extend('mobilephone', mobilephone)
extend('min_value', min_value)
extend('max_value', max_value)
extend('length', length)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BRow,
    BCol,
    BButton,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isPanelOrderPayoutBonusesActive',
    event: 'update:is-panel-order-payout-bonuses-active',
  },
  props: {
    isPanelOrderPayoutBonusesActive: {
      type: Boolean,
      required: true,
    },
    availableBonuses: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const refPayoutForm = ref(false)
    return { refPayoutForm, required, min_value }
  },
  data() {
    return {
      selected: '',
      sumBonusesValue: null,
      userNameValue: '',
      cardNumberValue: '',
      phoneNumberValue: null,

      resolveUserPayoutMotivationStatus,
    }
  },

  methods: {
    sumBonusesFormat(value) {
      if (parseInt(value, 10) > parseInt(this.availableBonuses, 10)) {
        this.sumBonusesValue = ''
        return this.phoneNumberValue
      }
      return value
    },
    reset() {
      this.selected = ''
      this.sumBonusesValue = ''
      this.userNameValue = ''
      this.cardNumberValue = ''
      this.phoneNumberValue = ''
    },

    onSubmit() {
      const data = {}

      if (this.selected === '1' && this.phoneNumberValue) {
        const details = this.phoneNumberValue.replace(/[^0-9]/g, '').substring(1)
        Object.assign(data, { details, bonus: this.sumBonusesValue, payoutDataTypeId: parseInt(this.selected, 10) })
      }

      if (this.selected === '2' && this.cardNumberValue) {
        const details = this.cardNumberValue.replace(/[^0-9]/g, '')
        Object.assign(data, {
          ownerPaymentData: this.userNameValue, details, bonus: this.sumBonusesValue, payoutDataTypeId: parseInt(this.selected, 10),
        })
      }
      this.reset()
      createPayout({ ...data }).then(() => {
        this.$emit('update:is-panel-order-payout-bonuses-active', false)
        this.$emit('refetch-data')
        this.$swal({
          icon: 'success',
          title: 'Обработка заявки!',
          text: 'Заявка успешно обработана.',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
