import payoutRepository from '@/repository/payoutRepository'
import payoutDataRepository from '@/repository/payoutDataRepository'

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import ability from '@/libs/acl/ability'
import { getUserId } from './userService'

export function elementList() {
  const Columns = [
    { key: 'payoutDetails', label: 'Платежный реквизит' },
    { key: 'bonus', label: 'Бонусы', sortable: true },
    { key: 'status', label: 'Статус' },
    { key: 'title', label: 'Способ выплаты' },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
    { key: 'updatedAt', label: 'Дата изменения', sortable: true },
  ]

  const refPayout = ref(null)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const selectedStatus = ref(null)
  const userId = getUserId()

  const dataMeta = computed(() => {
    const localItemsCount = refPayout.value ? refPayout.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'PayoutManage')) {
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }

  const refetchData = () => {
    refPayout.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  const fetchItems = (ctx, callback) => {
    payoutRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      status: selectedStatus.value || null,
      userId,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        const payoutList = items.map(x => {
          const {
            bonus, createdAt, updatedAt,
          } = x
          const { details, ownerPaymentData, payoutDataType } = x.payoutData
          const payoutDetails = { details, ownerPaymentData: ownerPaymentData || '' }
          const { title } = payoutDataType
          return {
            bonus, createdAt, updatedAt, status: x.status, title, payoutDetails,
          }
        })

        callback(payoutList)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  const clearSelectTable = () => {
    refPayout.value.clearSelected()
  }

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPayout,
    Columns,
    clearSelectTable,
    selectedStatus,

    refetchData,

  }
}

export const createPayout = async params => {
  try {
    const result = await payoutDataRepository.createPayout(params)

    return result
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return []
  }
}
