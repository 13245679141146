<template>
  <b-row>
    <b-col cols="12">
      <b-card
        no-body
        class="mb-0"
      >
        <panel-order-payout-bonuses
          v-if="availableAccept"
          :is-panel-order-payout-bonuses-active.sync="isPanelOrderPayoutBonusesActive"
          :available-bonuses="availableAccept"
          @refetch-data="refetch()"
        />
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показывать по</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>записей</label>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                />
                <b-button
                  class="create-payout"
                  size="sm"
                  variant="primary"
                  :disabled="available < 500"
                  @click="isPanelOrderPayoutBonusesActive=true"
                >
                  создать заявку на выплату бонусов
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refBonusDocumentsTable"
          class="position-relative"
          :items="fetchItems"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Совпадающих записей не найдено"
          :sort-desc.sync="isSortDirDesc"
          table-class="text-center"
        >

          <template #cell(createdAt)="data">
            {{ formatDate(data.value) }}
          </template>

          <template #cell(active)="data">
            <feather-icon
              v-if="data.value == 1"
              class="text-success"
              icon="EyeIcon"
              size="16"
            />
            <feather-icon
              v-else
              class="text-danger"
              icon="EyeOffIcon"
              size="16"
            />
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BFormInput, BPagination, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ability from '@/libs/acl/ability'
import { formatDate } from '@/libs/helper'
import { ref } from '@vue/composition-api'
import PanelOrderPayoutBonuses from '../components/PanelOrderPayoutBonuses.vue'

import actionProductList from './bonusDocuments'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BPagination,
    BButton,
    PanelOrderPayoutBonuses,

    vSelect,
  },
  props: {
    availableAccept: {
      type: Number,
      required: true,
      default: 0,
    },
    available: {
      type: Number,
      required: true,
      default: 0,
    },
    refetch: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const isPanelOrderPayoutBonusesActive = ref(false)

    const {
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBonusDocumentsTable,
      tableColumns,

      refetchData,
      clearSelectTable,

    } = actionProductList()

    return {
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBonusDocumentsTable,
      tableColumns,

      refetchData,
      clearSelectTable,

      formatDate,
      isPanelOrderPayoutBonusesActive,
      ability,
    }
  },
}
</script>

<style lang="scss" scoped>

@media(max-width:840px) {
      .create-payout {
        display: flex;
        align-items: center;
        height: 40px;
}
    }
</style>
