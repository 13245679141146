<template>
  <section
    v-if="bonusesList"
    id="dashboard-analytics"
  >
    <b-row class="match-height d-flex justify-content-between">
      <b-col
        xl="4"
        lg="6"
        md="6"
        sm="6"
        xs="12"
      >
        <bonus
          v-if="bonusesList.bonusBalance"
          :bonus-balance="bonusesList.bonusBalance"
        />
      </b-col>

      <b-col
        cols="8"
      >
        <bonuses-card
          :data="bonusesList"
          :is-client="true"
          :refetch="refetch"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <div class="h4">
          Движение по счету
        </div>
      </b-col>
      <b-col cols="12">

        <bonus-documents
          v-if="bonusesList.availableAccept || bonusesList.available"
          :available-accept="bonusesList.availableAccept"
          :available="bonusesList.available"
          :refetch="refetch"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BRow, BCol } from 'bootstrap-vue'
import Bonus from '@/views/main/client/Bonus.vue'
import { getBonusUserList } from '@/application/bonusService'
import store from '@/store'
import BonusesCard from '@/components/bonus/BonusesCard.vue'
import BonusDocuments from './BonusDocuments.vue'

export default {
  components: {
    BRow,
    BCol,

    Bonus,
    BonusesCard,
    BonusDocuments,
  },

  setup() {
    const isLoading = ref(true)
    const bonusesList = ref({})

    const refetch = () => new Promise(resolve => {
      getBonusUserList().then(res => {
        const {
          accruals,
          available,
          availableAccept,
          bonusBalance,
          debiting,
          expectation,
          payout,
          review,
        } = res

        bonusesList.value = {
          accruals,
          available,
          availableAccept,
          bonusBalance,
          debiting,
          expectation,
          payout,
          review,
        }
        resolve(bonusesList)
        isLoading.value = false
      }).catch(error => {
        store.commit('app/SET_ERROR', error)
        resolve(null)
      })
    })
    refetch()
    return {
      refetch,
      isLoading,
      bonusesList,
    }
  },
}
</script>
