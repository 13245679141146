import { ref, watch, computed } from '@vue/composition-api'
import bonusRepository from '@/repository/bonusRepository'
import { getUserId } from '@/application/userService'
import store from '@/store'

export default function bonusDocuments() {
  const tableColumns = [
    { key: 'documentTypeTitle', label: 'Название документа' },
    { key: 'documentId', label: 'Номер документа', sortable: true },
    { key: 'bonus', label: 'Сумма бонусов', sortable: true },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
  ]

  const refBonusDocumentsTable = ref(null)
  const userId = getUserId()

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refBonusDocumentsTable.value ? refBonusDocumentsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refBonusDocumentsTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    bonusRepository.bonusDocumentList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      userId,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items.map(item => ({
          ...item,
          createdUser: !item.createdUser ? '' : `${item.createdUser.firstName || ''} ${item.createdUser.lastName || ''}`,
          updatedUser: !item.updatedUser ? '' : `${item.updatedUser.firstName || ''} ${item.updatedUser.lastName || ''}`,
        })))
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  const clearSelectTable = () => {
    refBonusDocumentsTable.value.clearSelected()
  }

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBonusDocumentsTable,

    refetchData,
    clearSelectTable,
    tableColumns,
  }
}
