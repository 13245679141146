import conect from './api'

const { axiosIns } = conect()

export default {
  async elementList(params = {}) {
    const { error, result } = (await axiosIns.get('/payout-data-type', { params })).data
    if (error) throw error

    return result
  },

  async createPayout(...params) {
    const { error, result } = (await axiosIns.post('/payout', ...params)).data
    if (error) throw error

    return result
  },
}
